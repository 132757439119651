<template>
<div id="app">
  <v-app id="inspire">
    <v-app id="inspire">
      <v-navigation-drawer
        v-model="drawer"
        color="#009EE3"
        dark
        app
      >
        <v-list dense>
          <v-list-item link to="/">
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Bienvenido</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/calcula-tus-gastos">
            <v-list-item-action>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Calcula tus gastos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  
      <v-app-bar
        app
        color="#009EE3"
        dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Bee 360°</v-toolbar-title>
        <v-spacer></v-spacer>
        <router-link to="/" style="text-decoration: none;" v-if="!isHome"><v-icon link to="/">mdi-home</v-icon></router-link>
      </v-app-bar>
  
      <v-main>
        <v-container
          class="fill-height"
          fluid
        >
        <router-view />
        </v-container>
      </v-main>
      <v-footer
        color="#009EE3"
        app
      >
        <span class="white--text">&copy; {{ new Date().getFullYear() }} Bee 360° Consultoría y Estrategia</span>
      </v-footer>
    </v-app>
  </v-app>
</div>
</template>

<script>
//import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  props: {
      source: String,
  },
  components: {
    //HelloWorld,
  },

  data: () => ({
    //
    drawer: null,
  }),
  computed: {
      isHome() {
         return this.$route.name === 'Home'
      }
  }
};
</script>
