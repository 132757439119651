<template>
  <v-container class="home">
  <v-row>
    <v-spacer></v-spacer>
    <v-col class="text-center">
        <img alt="BEE 360°" src="../assets/logo.svg">
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <v-row class="my-10">
    <v-spacer></v-spacer>
    <v-col class="text-center">
        <v-btn x-large color="success" dark to="/calcula-tus-gastos">Calcula tus gastos</v-btn>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <v-row class="">
    <v-col class="text-center">
    <div><h3>Nadia O. Llauger Carbajal</h3></div>
    <div class="subtitle-2 my-1"><span style="background: #FFDE00; color:#696966; padding: 5px;">DIRECTOR GENERAL / ASESOR 360</span></div>
    <div class="subtitle-2"><a href="tel:3338150320" style="color:#696966; padding: 5px; text-decoration: none;">Cel: (33) 38.15.03.20</a> <a href="https://wa.me/+5213338150320" style="text-decoration: none;"><v-icon color="#2BA639">mdi-whatsapp</v-icon></a></div>
    <div class="subtitle-2"><v-icon color="#2459A0">mdi-facebook</v-icon> <a href="https://www.facebook.com" style="color: #000; text-decoration: nonecolor: #000; text-decoration: none;;">Total Bee 360</a></div>
              
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    //HelloWorld
  }
}
</script>
